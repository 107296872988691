import Swal from "sweetalert2";
export async function askConfirmation(
  message,
  confirmButtonText,
  cancelButtonText
) {
  let isConfirmed = false;
  await Swal.fire({
    icon: "question",
    title: "<small><b>Confirmation</b></small>",
    html: message,
    //showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      "<span style='font-size:14px;'>" +
      (confirmButtonText === "" || confirmButtonText === null
        ? ""
        : confirmButtonText) +
      " </span>",
    cancelButtonText:
      cancelButtonText === "" || cancelButtonText === null
        ? "No"
        : cancelButtonText,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#121212",
    //dangerMode: false,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
    }
  });
  return isConfirmed;
}
export async function askConfirmationInputTextarea(
  message,
  confirmButtonText,
  cancelButtonText,
  inputPlaceholder,
  maxlength = 100
) {
  let isConfirmed = false;
  let value = "";
  await Swal.fire({
    icon: "question",
    title: "<small><b>Confirmation</b></small>",
    html: message,
    //showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      "<span style='font-size:14px;'>" +
      (confirmButtonText === "" || confirmButtonText === null
        ? ""
        : confirmButtonText) +
      " </span>",
    cancelButtonText:
      cancelButtonText === "" || cancelButtonText === null
        ? "No"
        : cancelButtonText,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#121212",
    //dangerMode: false,
    allowOutsideClick: false,
    input: "textarea",
    //inputLabel: inputPlaceholder,
    inputPlaceholder: `Type your ${
      inputPlaceholder?.toString()?.toLowerCase() ?? "message"
    } here...`,
    inputAttributes: {
      "aria-label": `Type your ${
        inputPlaceholder?.toString()?.toLowerCase() ?? "message"
      } here`,
      maxlength: maxlength ?? 100,
      autocapitalize: "off",
      autocorrect: "off",
    },
    inputValidator: (result) => {
      return (
        !result && `Please enter ${inputPlaceholder?.toString()?.toLowerCase()}`
      );
    },
    //preConfirm: (result) => ({}),
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
      value = result?.value ?? "";
    }
  });
  return { isConfirmed, value };
}
export async function askConfirmationInputTextareaCheckbox(
  message,
  confirmButtonText,
  cancelButtonText,
  inputPlaceholder,
  checkboxPlaceholder,
  inputMaxlength = 100,
  isCheckBoxRequired = false
) {
  let isConfirmed = false;
  let value = "";
  let isChecked = false;
  inputMaxlength = inputMaxlength < 100 ? 100 : inputMaxlength;
  await Swal.fire({
    icon: "question",
    title: `<small><b>Confirmation</b></small></br><small>${message}</small>`,
    html: `
   <textarea id="textarea" aria-label="Type your reason here" maxlength=${inputMaxlength} autocapitalize="off" autocorrect="off" class="swal2-textarea" placeholder="Type your reason here..." style="display: flex; width:82%"></textarea>
   <label class="swal2-checkbox">
   <input type="checkbox" id="isAgreed" >${checkboxPlaceholder}</label>
  `,
    //showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      "<span style='font-size:14px;'>" +
      (confirmButtonText === "" || confirmButtonText === null
        ? ""
        : confirmButtonText) +
      " </span>",
    cancelButtonText:
      cancelButtonText === "" || cancelButtonText === null
        ? "No"
        : cancelButtonText,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#121212",
    allowOutsideClick: false,
    preConfirm: () => {
      const inputValue = document.getElementById("textarea").value;
      const isAgreed = document.getElementById("isAgreed").checked;
      if (!inputValue) {
        Swal.showValidationMessage(
          `Please enter ${inputPlaceholder?.toString()?.toLowerCase()}`
        );
      } else if (isCheckBoxRequired && !isAgreed) {
        Swal.showValidationMessage("Please agree to the terms");
      } else {
        return { inputValue, isAgreed };
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      debugger;
      isConfirmed = true;
      value = result.value.inputValue;
      isChecked = result.value.isAgreed;
    }
  });
  return { isConfirmed, value, isChecked };
}
export async function askConfirmationCheckbox(
  message,
  confirmButtonText,
  cancelButtonText,
  inputLabel
) {
  let isConfirmed = false;
  let isChecked = false;
  await Swal.fire({
    icon: "question",
    title: "<small><b>Confirmation</b></small>",
    html: message,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      "<span style='font-size:14px;'>" +
      (confirmButtonText === "" || confirmButtonText === null
        ? ""
        : confirmButtonText) +
      " </span>",
    cancelButtonText:
      cancelButtonText === "" || cancelButtonText === null
        ? "No"
        : cancelButtonText,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#121212",
    //dangerMode: false,
    allowOutsideClick: false,
    input: "checkbox",
    inputPlaceholder: inputLabel,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
      isChecked = result?.value ?? false;
    }
  });
  return { isConfirmed, isChecked };
}
export async function showResponseMessage({ success, message }) {
  let isConfirmed = false;
  await Swal.fire({
    allowOutsideClick: false,
    icon: success ? "success" : "error",
    title: success ? "Success" : "Oops...",
    text:
      success === false && (message === null || message === "")
        ? "Unable to process the request."
        : message === null || message === ""
        ? "Request processed successfully."
        : message,
    //html: "Sorry! We are unable to process you request.<br/>Please try after some times.Sorry! We are unable to process you request.<br/>Please try after some times.",
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
    }
  });
  return isConfirmed;
}
export async function showUnderDevelopmentMessage() {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "warning",
    title: "Request Failed!",
    html: "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
export async function showSuccessMessage(message, title = "") {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "success",
    title,
    text: message ?? "Success",
  });
}
export async function showWarningMessage(message, title = "") {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "warning",
    title,
    html:
      message ??
      "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
export async function showErrorMessage(message) {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "error",
    title: "Oops...",
    html:
      message ??
      "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
