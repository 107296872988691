import axios from "axios";
//import platform from "";
import { CurrencyIcon, UserType } from "./fixcode";
import secureLocalStorage from "react-secure-storage";
export const LocalStorage = {
  IsAuthorized:
    JSON.parse(sessionStorage.getItem("session_authorized")) === true,
  SessionId: sessionStorage.getItem("session_authtoken"),

  UserId: Number(sessionStorage.getItem("session_userid")),
  UserName: sessionStorage.getItem("session_username"),
  FirstName: sessionStorage.getItem("session_firstname"),
  LastName: sessionStorage.getItem("session_lastname"),
  Password: sessionStorage.getItem("session_password"),

  UserTypeId: Number(sessionStorage.getItem("session_usertypeid")),
  UserTypeName: sessionStorage.getItem("session_usertypename"),

  PhoneNumber: sessionStorage.getItem("session_mobile"),
  Email: sessionStorage.getItem("session_email"),
  ProfilePhotoUrl: sessionStorage.getItem("session_profilephotourl"),

  CustomerId: Number(sessionStorage.getItem("session_customerid")),
  BranchId: Number(sessionStorage.getItem("session_branchid")),
  BranchName: sessionStorage.getItem("session_branchname"),

  IsAdminUserLoggedIn:
    Number(sessionStorage.getItem("session_usertypeid")) ===
      UserType.SystemAdministrator ||
    Number(sessionStorage.getItem("session_usertypeid")) ===
      UserType.Administrator,
  IsSourcingManagerAdminLoggedIn:
    Number(sessionStorage.getItem("session_usertypeid")) ===
    UserType.SourcingManagerAdmin,
  CurrencyIcon: {
    Normal: sessionStorage.getItem("session_currencyicon"),
    Xs: `${sessionStorage.getItem("session_currencyicon")} fa-xs`,
    Sm: `${sessionStorage.getItem("session_currencyicon")} fa-sm`,
    Lg: `${sessionStorage.getItem("session_currencyicon")} fa-lg`,
  },
  UserFeatureSetup: sessionStorage.getItem("session_userFeatureSetup"),
  AmeyoUserName: sessionStorage.getItem("session_ameyousername"),
  AmeyoPasscode: sessionStorage.getItem("session_ameyopasscode"),
  AmeyoAccess: sessionStorage.getItem("session_ameyoaccess") === "true" ? true : false,
  TrackingGuid: sessionStorage.getItem("session_trackingguid"),
};

export const SetSessionItem = (key, value) => {
  sessionStorage.removeItem(`session_${key}`);
  if (isNaN(value)) {
    sessionStorage.setItem(`session_${key}`, ToString(value));
  } else {
    sessionStorage.setItem(`session_${key}`, value);
  }
};

export const ClearSessionItems = () => {
  sessionStorage.clear();
};
function ToString(value) {
  try {
    return (value ?? "").trim();
  } catch (error) {
    console.log(error);
  }
  return "";
}

export const getCurrentLocation = async () => {
  let lat = 0,
    lng = 0;
  let ip = "";
  try {
    const curlocation = await axios.get("https://ipapi.co/json");
    if (curlocation?.data) {
      lat = curlocation.data.latitude;
      lng = curlocation.data.longitude;
      ip = curlocation.data.ip;
    }
  } catch (error) {
    console.log(error);
  }
  return { lat, lng, ip };
};

export const getCurrencyIcon = (currencyCode) => {
  let currencyIcon = CurrencyIcon.ruppesFormat;
  if (currencyCode !== null) {
    switch (currencyCode.toLowerCase()) {
      case "inr":
        currencyIcon = CurrencyIcon.ruppesFormat;
        break;
      default:
        break;
    }
  }
  return currencyIcon;
};

export const GetFeatureAccess = (featureId) => {
  return {
    ...JSON.parse(LocalStorage.UserFeatureSetup).filter(
      (item) => item.featureId === featureId
    )[0],
  };
};
